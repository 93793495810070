<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
    </template>

    <!-- 搜索栏 -->
    <template v-slot:handles-right>
      <a-form-model layout="inline"
        :form="searchForm"
        @submit="handleSearchSubmit"
        @submit.native.prevent
      >
        <a-form-model-item>
          <a-input style="width: 260px" v-model="searchForm.keyword" placeholder="请输入企业名称/营业执照"></a-input>
        </a-form-model-item>
        <a-form-model-item style="margin-right: 0">    
          <a-button class="mp-grey-btn" html-type="submit">
            查询
          </a-button>
          <a-button class="mp-grey-btn" style="margin-left: 8px" @click="resetSearchForm">
            重置
          </a-button>
        </a-form-model-item>    
      </a-form-model>
    </template>

    <!-- 列表 -->
    <div class="mp-table-wrap">
      <a-table :columns="columns" 
        :data-source="tableData" 
        rowKey="authId"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >   
        <div slot="serial" slot-scope="text,record,index">{{index+1}}</div>
        <div slot="companyType" slot-scope="text">{{text|companyTypeText}}</div>
        <div slot="handles" slot-scope="text,record">
          <a-button type="link" size="small" @click="toDetail(record)">查看详情</a-button>
        </div>
      </a-table>
    </div>
  </page-head-wrapper>
</template>

<script>
import { authCompanyList } from '@/api/company'
const columns = [
  {
    align: 'center',
    title: '序号',
    width: '80px',
    scopedSlots: { customRender: 'serial'}
  },
  {
    align: 'center',
    title: '企业名称',
    dataIndex: 'companyName'
  },
  {
    align: 'center',
    title: '企业类型',
    dataIndex: 'companyType',
    scopedSlots: { customRender: 'companyType'}
  },
  {
    align: 'center',
    title: '营业执照',
    dataIndex: 'companyLicenseNumber',
  },
  {
    align: 'center',
    title: '公司地址',
    dataIndex: 'companyAddress',
    ellipsis: true,
  },
  {
    align: 'center',
    title: '联系人员',
    dataIndex: 'companyKeyPerson'
  },
  {
    align: 'center',
    title: '联系电话',
    dataIndex: 'companyKeyPersonPhone'
  },
  {
    align: 'center',
    title: '相关操作',
    width: 160,
    scopedSlots: { customRender: 'handles'}
  },
]
export default {
  name: 'static-data-company-pending-list',
  keep_alive_tab: true,
  data() {
    return {
      // 搜索表单
      searchForm: {
        keyword: '',
      },
      // 列表
      tableData: [],
      columns,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true
      },
    }
  },
  filters: {
    companyTypeText: function(value) {
      return value == 1 ? '工商型': value == 2 ? '个人型' : '-'
    }
  },
  created() {
    console.log('created.....')
    this.authCompanyList()
    this.$bus.$on('listen-pending-update', (id) => {
      console.log('listen-pending-update:id', id)
      this.authCompanyList()
    })
  },
  mounted() {
    console.log('mounted...')
  },
  beforeDestroy() {
    console.log('beforeDestroy...')
    this.$bus.$off('listen-pending-update')
  },
  methods: {
    authCompanyList() {
      this.loading = true
      authCompanyList({
        current: this.pagination.current,
        size: this.pagination.pageSize,
        keyword: this.searchForm.keyword,
        authResult: 'p'
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.tableData = res.data.records
          this.pagination.total = res.data.total
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.loading = false
      })
    },
    handleTableChange(pagination) {
      // console.log('Various parameters', pagination);
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.authCompanyList()
    },
    // 搜索表单
    handleSearchSubmit() {
      console.log(this.searchForm)
      this.pagination.current = 1
      this.authCompanyList()
    },
    // 重置搜索
    resetSearchForm() {
      console.log('resetSearchForm')
      this.pagination.current = 1
      this.searchForm.keyword = ''
      this.authCompanyList()
    },
    // 刷新操作
    onRefresh() {
      this.pagination.current = 1
      this.searchForm.keyword = ''
      this.authCompanyList()
    },
    toDetail(record) {
      this.pushTabPage({
        path: '/static-data/company/workbench/pending-detail?id=' + record.authId,
        startIndex: 3
      })
    },
    
  }
}
</script>